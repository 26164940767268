import {Accordion, Card, Col, Row} from 'react-bootstrap';
import LinkCard from "../events/LinkCard";

export default function FAQAccordion() {
  return (
    <>
      <Row>
        <p className={"fs-3"}>
          Get started
        </p>
      </Row>
      <Row>
        <Col md={6} xl={3}>
          <LinkCard title={"Student"} onClick={()=> window.open("https://scribehow.com/shared/2025_Student_Guide_Registering_for_the_NZPMC_Platform__CPL9hQ4MRjy_jkVWXx2elg", "_blank")} color={"#CAD5DCFF"}>
            Guide to create an account <i className="bi bi-box-arrow-up-right ms-1" />
          </LinkCard>
        </Col>
        <Col md={6} xl={3}>
          <LinkCard title={"Teacher"} onClick={()=> window.open("https://scribehow.com/shared/2025_Teacher_Guide_Registering_for_the_NZPMC_Platform__G7t5tFR-SqifoI6dkmLxtg", "_blank")} color={"#CAD5DCFF"}>
            Guide to create an account <i className="bi bi-box-arrow-up-right ms-1" />
          </LinkCard>
        </Col>
        <Col md={6} xl={3}>
          <LinkCard title={"Competition Registration"} onClick={()=> window.open("https://scribehow.com/shared/2025_Student_Guide_Registering_for_an_Event__wcIRQb5xRmW1OxJ_13OHvQ", "_blank")} color={"#CAD5DCFF"}>
            Registering for an event <i className="bi bi-box-arrow-up-right ms-1" />
          </LinkCard>
        </Col>
      </Row>
      <Row>
        <p className={"fs-3"}>
          Sign-up Related Questions
        </p>
      </Row>
      <Accordion defaultActiveKey={"0"}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Do I need a teacher to sign up?</Accordion.Header>
          <Accordion.Body>
            No. Unlike previous years, you can now simply register to the events without being associated to a teacher.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Does the round 1 registration fee include the fee for finals?</Accordion.Header>
          <Accordion.Body>
            No, they are separate. Students eligible for finals will
            receive an email after round 1 for a separate finals registration. Students may also need to arrange
            their own accommodation if they are traveling to compete in the finals.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Is NZPMC GST registered?</Accordion.Header>
          <Accordion.Body>
            No, NZPMC is not GST-registered.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Can the school pay the payment in bulk?</Accordion.Header>
          <Accordion.Body>
            No, students have options to pay through PoliPay (accepts major NZ banks) and Stripe (accepts debit/credit cards).
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Is there financial support for registration fees?</Accordion.Header>
          <Accordion.Body>
            We apply discount based on EQI index of school. See
            {' '}
            <a href="https://butternut-pixie-b59.notion.site/Discount-on-Competition-Fee-c1a891c4e9a4424488348381526a4ab5">EQI discount</a>
            {' '}
            for pricing policy.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
