import { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import { ExamContext } from '../../../pages/ExamPage';

function QuestionTypeShortAnswer({ question }) {
  const { userChoices, handleAnswer } = useContext(ExamContext);
  const [storedAnswer, setStoredAnswer] = useState(
    userChoices ? userChoices[question.id] : '',
  );
  return (
    <Form>
      <Form.Group>
        <Form.Label>Type answer below:</Form.Label>
        <Form.Control
          as="textarea"
          rows={8}
          value={storedAnswer}
          onChange={(e) => setStoredAnswer(e.target.value)}
          onBlur={() => handleAnswer(question.id, storedAnswer)}
        />
      </Form.Group>
    </Form>
  );
}

export default QuestionTypeShortAnswer;
