import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import { Alert, Button, Container } from 'react-bootstrap';
import { useContext, useEffect } from 'react';
import { PageContext } from '../context/PageContextProvider';
import { REGISTERED } from '../utils/Constants';

const pageMetadata = {
  titleLH: 'Exam',
  titleRH: 'has ended',
  body: 'The exam has completed and your attempt has been submitted. Nicely done!',
};

export default function ExamEndScreen() {
  const { setPageMetadata } = useContext(PageContext);
  const { eventCode } = useParams();
  const navigate = useHistory();

  useEffect(() => {
    setPageMetadata(pageMetadata);
  }, []);
  return (
    <>
      <span className={"fs-4 bi bi-check-circle-fill text-success"}>{" "}You have submitted your exam for {eventCode}</span><br/>
      <p className={"fs-5"}>
        Stay tuned for your results!
      </p>
      <p className="mt-3">
      </p>
        <Button onClick={() => navigate.push('/')}>Back to Dashboard</Button>
    </>
  );
}
