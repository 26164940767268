import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContextProvider';
import { PrettyForm } from '../forms/common/PrettyForm';
import FileInputV2 from '../forms/common/stateless/FileInputV2';
import RichTextInputV2 from '../forms/common/stateless/RichTextInputV2';
import SelectInputV2 from '../forms/common/stateless/SelectInputV2';
import TextInputV2 from '../forms/common/stateless/TextInputV2';
import { DIFFICULTY_TAG, DIVISION_TAG, TOPIC_TAG } from './QuestionAttributeConstants';

function CreateShortAnswerQuestion({ createQuestion }) {
  const { authRetrievedProfile } = useContext(AuthContext);

  const [question, setQuestion] = useState({
    questionOwner: authRetrievedProfile.name,
    type: 'ShortAnswer',
  });
  const [imageFile, setImageFile] = useState(null);

  const history = useHistory();


  return (
    <PrettyForm
      submitText="Create Question"
      cancelText="Cancel"
      onCancel={() => history.goBack()}
      onSubmit={() => createQuestion(question, imageFile)}
    >
      <TextInputV2 label="Title" onValueChange={(title) => setQuestion({ ...question, title })} required />
      <RichTextInputV2 label="Body" onValueChange={(body) => setQuestion({ ...question, body })} required />
      <FileInputV2 label="Image (Drag and Drop)" onValueChange={(image) => setImageFile(image)} />
      <TextInputV2 label="Correct Answer" onValueChange={(correctAns) => setQuestion({ ...question, correctAns })} required />
      <SelectInputV2
        label="Topic"
        onValueChange={(topic) => setQuestion({ ...question, topic })}
        options={TOPIC_TAG}
        required
      />
      <SelectInputV2
        label="Difficulty"
        onValueChange={(difficulty) => setQuestion({ ...question, difficulty })}
        options={DIFFICULTY_TAG}
        required
      />
      <SelectInputV2
        label="Division"
        onValueChange={(division) => setQuestion({ ...question, division })}
        options={DIVISION_TAG}
        required
      />
      <em>{`Question Owner: ${authRetrievedProfile?.name}`}</em>
    </PrettyForm>
  );
}


export default CreateShortAnswerQuestion;
