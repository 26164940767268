import { useEffect, useRef } from 'react';

export const examIsLive = (exam) => {
  if (exam?.examStartTime > new Date()) {
    return false;
  }
  if (exam?.examEndTime <= new Date()) {
    return false;
  }
  return true;
};

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
