import { forwardRef, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { SessionContext } from '../../../context/SessionContextProvider';
// eslint-disable-next-line import/no-cycle
import { PrettyFormGroup } from './FormComponents';

// IMPORTANT: required is by default true
const TextBoxInput = forwardRef(({
  label, field, required = true, enforceNumeric = false, onEnter = () => { },
}, ref) => {
  const { session, handleSessionChange } = useContext(SessionContext);

  const handleKeyUp = (e) => {
    e.preventDefault();
    if (e.key === 'Enter') {
      onEnter(e);
    }
  };

  return (
    <PrettyFormGroup label={label} required={required}>
      <Form.Control
        onChange={handleSessionChange(field)}
        type={enforceNumeric ? 'number' : 'text'}
        required={required}
        onKeyUp={handleKeyUp}
        ref={ref}
        name={field}
        value={session[field] ?? ''}
      />
    </PrettyFormGroup>
  );
});

export default TextBoxInput;
