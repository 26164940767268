import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router-dom';

function ExamStartModal({
  show, handleClose, eventCode, exam,
}) {
  const startTime = new Date(exam.examStartTime);
  const endTime = new Date(exam.examEndTime);
  // TODO - Set as actual duration (not yet in backend at time of this commit)
  const duration = 120;
  const examStarted = startTime < new Date();

  const history = useHistory();

  const startExam = () => {
    history.push(`/exam/${eventCode}`);
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton className={`p-4 pb-3 ${examStarted ? 'bg-success text-white' : 'bg-secondary text-white'}`}>
        <Modal.Title>{exam.eventName}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <h5>General Information</h5>
        <ul>
          <li>{`Exam Date - ${startTime.toLocaleDateString()}`}</li>
          <li>
            {`Time Frame - 
              ${startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
               to 
              ${endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
          </li>
          <li>{`Duration - ${duration} minutes`}</li>
          <li>{`Division - ${exam.division}`}</li>
        </ul>
        <h5>Instructions</h5>
        <ul>
          <li>Please start the examination within the indicated time frame</li>
          <li>Give every question a go! Even if you don't know the answer, it never hurts to make a guess</li>
          <li>The exam will auto-submit after your time runs out or when the submission deadline passes - depending on which comes first</li>
          <li className="text-danger">
            <b>DO NOT </b>
            try to get help from the internet or people around you
          </li>
        </ul>
        <p>
          <em>
            {examStarted
              ? 'The exam has begun! Press start when you are ready'
              : 'The exam has not started yet, please come back later'}
          </em>
        </p>
      </Modal.Body>
      <Modal.Footer className="p-3">
        <Button variant="outline-secondary" onClick={handleClose} className="w-25">
          Close
        </Button>
        {examStarted ? (
          <Button variant="success" onClick={startExam} className="w-25">
            Start
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}

export default ExamStartModal;
