import { useContext, useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

/**
 * Used for creating multiple choice questions
 */
function MultiChoiceBuilderV2({ label, onChoiceArrayChange, onCorrectChoiceIndexChange, choiceArraySize }) {
  const choiceComponents = [];

  for (let choiceIndex = 0; choiceIndex < choiceArraySize; choiceIndex++) {
    choiceComponents.push(
      <InputGroup key={choiceIndex} className="my-3">
        <InputGroup.Radio name={"same radio"} onChange={() => onCorrectChoiceIndexChange(choiceIndex)} required />
        <Form.Control onChange={(e) => onChoiceArrayChange(choiceIndex, e.target.value)} required />
      </InputGroup>,
    );
  }

  return <>
    <p>{label}</p>
    {choiceComponents}
  </>;
}

export default MultiChoiceBuilderV2;
