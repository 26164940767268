import { useState } from 'react';
import RichTextDisplay from '../richtext/RichTextDisplay';
import styles from './FocusInput.module.css';
import RichTextInputV2 from './RichTextInputV2';

/** A wrapper component around richtext to make it only be editable when focused */
export default function FocusInput({ outerClassName, ...richTextProps }) {
  const [focused, setFocused] = useState(false);

  return (
    <div
      className={`${styles.focusdiv} ${outerClassName}`}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      {focused ? <RichTextInputV2 {...richTextProps} /> : <RichTextDisplay {...richTextProps} />}
    </div>
  );
}
