import {
  ListGroup, ListGroupItem, Card, Stack,
} from 'react-bootstrap';
import { useContext } from 'react';
import classes from './QuestionNavScroll.module.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { ExamContext } from '../../pages/ExamPage';

export default function QuestionNavScroll({ questions }) {
  const { flags, toggleFlag } = useContext(ExamContext);

  return (
    <div className={classes.wrapper}>
      <Card className={classes.nav}>
        <Card.Body>
          <Card.Title>Questions</Card.Title>
          <hr />
          <Card className={classes.scroll}>
            <ListGroup>
              {questions?.map((question, index) => (
                <ListGroupItem key={`questionNavScroll-${question.id}`}>
                  <Stack direction="horizontal">
                    <Card
                      className={classes.item}
                      onClick={() => document.getElementById(question.id).scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      })}
                    >
                      <i>{`Question ${index + 1}`}</i>
                    </Card>
                    <Card
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFlag(question.id);
                      }}
                      className={classes.flag}
                    >
                      {flags?.includes(question.id) ? (
                        <i
                          className="bi bi-flag-fill"
                          style={{ color: 'orange' }}
                        />
                      ) : (
                        <i className="bi bi-flag" />
                      )}
                    </Card>
                  </Stack>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Card>
        </Card.Body>
      </Card>
    </div>
  );
}
