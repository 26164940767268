import { useContext, useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../components/common/Loading';
import { mockQuestions } from '../components/exampage/examMockData';
import CompactQuestion from '../components/examPreviewPage/CompactQuestion';
import { AuthContext } from '../context/AuthContextProvider';
import { PageContext } from '../context/PageContextProvider';
import useWithLoading from '../hooks/useWithLoading';
import { getExamForAdmin } from '../services/exams';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';

export function PreviewExamPage() {
  const { setPageMetadata } = useContext(PageContext);
  const { authRetrievedProfile } = useContext(AuthContext);
  const [questions, setQuestions] = useState(mockQuestions);
  const [loading, withLoading] = useWithLoading();
  const { examCode } = useParams();
  const history = useHistory();

  useEffect(() => {
    withLoading(
      getExamForAdmin(authRetrievedProfile, examCode),
      (response) => setQuestions(response.data.questions),
      ALERT_ERROR_MESSAGE,
    );
  }, [authRetrievedProfile]);

  useEffect(() => {
    setPageMetadata(undefined);
  }, []);

  function changeQuestion(newQuestion) {
    const newQuestions = questions.map((question) => (question.id === newQuestion.id ? newQuestion : question));
    setQuestions(newQuestions);
  }

  return (
    <Loading loading={loading}>
      <Row>
        <Button
          variant="outline-secondary"
          className="mt-3 col-lg-7 mx-auto"
          onClick={() => history.goBack()}
        >
          Return
        </Button>
        {questions.map((question) => (
          <CompactQuestion
            key={question.id}
            question={question}
            changeQuestion={changeQuestion}
          />
        ))}
        <Button
          className="mt-3 col-lg-7 mx-auto"
          variant="outline-secondary"
          onClick={() => history.push(`/admin/createQuestion/${examCode}`)}
        >
          Add Question
        </Button>
      </Row>
    </Loading>
  );
}
