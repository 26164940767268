import { Form } from 'react-bootstrap';

export default function PreviewShortAnswer({ question, changeQuestion }) {
  function changeAnswerUi(newAnswer) {
    changeQuestion({ ...question, correctAns: newAnswer });

    // Insert server & error handling logic
    // On fail -> setQuestions(oldQuestions);
  }

  return (
    <Form>
      <Form.Group>
        <Form.Label>Correct answer:</Form.Label>
        <Form.Control
          value={question.correctAns}
          onChange={(e) => changeAnswerUi(e.target.value)}
          onBlur={() => { /* nothing for now */ }}
        />
      </Form.Group>
    </Form>
  );
}
