import axios from 'axios';

export const createQuestion = (auth, question, examCode, imageFile) => async () => {
  const formData = new FormData();

  formData.append('questionJson', JSON.stringify(question));

  if (imageFile) {
    formData.append('file', imageFile);
  }

  return await axios.post(`/questions/${examCode}?email=${auth.email}&code=${auth.code}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};