import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { ADMIN_ROLE, getRole } from '../../../../utils/roleComputation';
import { EventContext } from '../../../../pages/EventPage';

export default function AttachSeniorExamButton({className}) {
  const history = useHistory()
  const {authRetrievedProfile: auth} = useContext(AuthContext);
  const { eventCode } = useContext(EventContext);

  if(getRole(auth) !== ADMIN_ROLE) return null

  return (<a className={className} onClick={() => history.push(`/admin/createExam/${eventCode}/Senior`)}>
    {" "} Create Exam (Senior)
  </a>)
}
