import { Button, Col } from 'react-bootstrap';
import { useContext } from 'react';
import { EventContext } from '../../../../pages/EventPage';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { getRole, STUDENT_ROLE } from '../../../../utils/roleComputation';
import useSimpleModal from '../../../../hooks/useSimpleModal';

function ResultsCard({className}) {

  const {authRetrievedProfile: auth} = useContext(AuthContext)
  const [setModalVisible, ModalWrapper] = useSimpleModal()
  const {userTicket} = useContext(EventContext)
  const {result} = userTicket || {}

  if (getRole(auth) !== STUDENT_ROLE) return null

  return (
    <>
      <ModalWrapper title={`Performance Summary`}>
        <ResultPopup />
      </ModalWrapper>
      {result && <a className={className} onClick={setModalVisible}>
        View Results
      </a>}
    </>
  )
}


function ResultPopup(){
  const {userTicket, eventName, eventCode} = useContext(EventContext)
  const {result, personSnapshot} = userTicket || {}
  const {island, yearLevel} = personSnapshot || {}

  if(!result) return null

  const division = yearLevel <= 11 ? 'Junior' : 'Senior'
  const titles = `(${result.titles.join(', ')})`

  const links = result.attachmentURLs.map(pdfUrl =>{
    return <li>
      <b>Certificate:</b> <a href={pdfUrl} target="_blank" rel="noreferrer"><i className="bi bi-box-arrow-up-right"></i></a>
    </li>
  })

  return (
    <Col xs={12} lg={12} className="mb-3">
      <ul>
        <li><b>Competition Name:</b> {eventName}</li>
        <li><b>Competition Code:</b> {eventCode}</li>
        <li><b>Score:</b> {result.score}</li>
        <li><b>Titles:</b> {titles}</li>
        <li><b>Division:</b> {division}</li>
        <li><b>Island:</b> {island}</li>
        {links}
      </ul>
    </Col>
  )

}

export default ResultsCard;
