import { EditorContent, useEditor } from '@tiptap/react';
import 'katex/dist/katex.min.css';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import styles from '../richtext/RichTextInput.module.css';
import RichTextMenu from '../richtext/RichTextMenu';
import { RICH_TEXT_EXTENSIONS } from '../richtext/config';

/**
 * Used for rich text input
 */
export default function RichTextInputV2({
  label, content, onValueChange, required, ...rest
}) {
  const editor = useEditor({

    onUpdate({ editor }) {
      onValueChange?.(editor.getHTML());
    },
    content,
    extensions: [...RICH_TEXT_EXTENSIONS],
  });

  useEffect(() => {
    if (!editor) return;
    const cursorLocation = editor.state.selection.$anchor.pos;
    editor.commands.setContent(content);
    editor.commands.focus(cursorLocation);
  }, [editor, content]);

  if (!editor) return null;

  return (
    <CustomPrettyFormGroup label={label} required={required}>
      <div {...rest} className={`${styles.editor} ${rest.className}`}>
        <RichTextMenu editor={editor} />
        <div className="p-3">
          <EditorContent editor={editor} contentEditable="false" />
        </div>
      </div>
    </CustomPrettyFormGroup>
  );
}

// PrettyFormGroup without my-3: Hajin says a little duplication is okay
function CustomPrettyFormGroup({
  label, children, required = false,
}) {
  return (
    <Form.Group>
      {label && (
        <Form.Label>
          {label}
          {required && <span style={{ color: 'red' }}>{' *'}</span>}
        </Form.Label>
      )}
      {children}
    </Form.Group>
  );
}
