import { useContext, useState } from 'react';
import { getNowNZT, toDateTimeInputValueString, toNZTString } from '../../../../utils/DateTimeUtils';
import { PrettyFormGroup } from '../FormComponents';
import { toast } from 'react-toastify';

/**
 * This is a NZT date picker
 * @param state
 * @param label
 * @param onValueChange
 * @param required
 * @returns {JSX.Element}
 * @constructor
 */
export default function DatePickerV2({
  label, onValueChange, required = false,
}) {
  if(onValueChange === undefined) {
    toast.error('StatelessDatePicker requires a onValueChange prop');
  }

  const [date, setDate] = useState(null); // just cuz, I want nzt to be displayed as it changes

  const onChange = (event) => {
    const selectedDate = new Date(event?.target?.value);
    onValueChange(selectedDate);
    setDate(selectedDate);
  };

  return (
    <PrettyFormGroup label={label} required={required}>
      <p>
        <DateInput date={date} onChange={onChange} />
        <TimeDisplay date={date} />
      </p>
    </PrettyFormGroup>
  );
}


function DateInput({date, onChange}) {
  return <input
    className="form-select"
    style={{
      backgroundImage: 'none',
      paddingRight: '12px'
    }} // form-select is not intended for this
    type="datetime-local"
    id="scheduled"
    onChange={onChange}
    value={toDateTimeInputValueString(date)}
  />
}

function TimeDisplay({ date }) {
  return <small>{toNZTString(date) === 'Invalid Date' ? `Now: ${getNowNZT()}` : toNZTString(date)}</small>
}
