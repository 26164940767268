import { useContext, useState } from 'react';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContextProvider';
import useWithLoading from '../../hooks/useWithLoading';
import { onEditStudentAsync } from '../../services/student';
import { ALERT_ERROR_MESSAGE } from '../../utils/ResponseUtils';
import Loading from '../common/Loading';
import BoxShadowCard from '../common/layout/BoxShadowCard';
import SchoolCard from '../dashboard/SchoolCard';
import TextBoxInput from '../forms/common/TextBoxInput';
import StudentCard from './cards/StudentCard';
import TextInputV2 from '../forms/common/stateless/TextInputV2';

function StudentAccountPage() {
  const { authRetrievedProfile: auth } = useContext(AuthContext);

  return (
    <>
      <Row>
        <Col xs={6}>
          <StudentCard student={auth} />
          <br />
          <SchoolCard teacher={auth.teacher} />
        </Col>
        <Col xs={6}>
          <BoxShadowCard>
            <StudentEditDetailsCard />
          </BoxShadowCard>
        </Col>
      </Row>
    </>
  );
}

function StudentEditDetailsCard() {
  const [newDetails, setNewDetails] = useState({
    firstName: null,
    middleName: null,
    lastName: null,
  });
  const { authRetrievedProfile, setAuthRetrievedProfile } = useContext(AuthContext);
  const [loading, withLoading] = useWithLoading();

  const onSubmit = () => {
    // Validation
    if (!newDetails.firstName || !newDetails.lastName) {
      toast.error('First Name and Last Name are required.');
      return;
    }

    withLoading(
      onEditStudentAsync(authRetrievedProfile, newDetails),
      (res) => {
        setAuthRetrievedProfile({
          ...authRetrievedProfile,
          firstName: res.data.firstName,
          middleName: res.data.middleName,
          lastName: res.data.lastName,
        });
        // apparently causes full page reload, which is nasty, but I couldn't find a better alternative with react-router-dom-v5
        // eslint-disable-next-line no-restricted-globals
        history.go(0);
      },
      ALERT_ERROR_MESSAGE,
    );
  };

  return (
    <Loading loading={loading}>
      <b>Do you need to edit your name?</b>
      <br />
      <small>Be sure to get it right before you register for any event.</small>
      <TextInputV2 label="First Name" onValueChange={firstName => setNewDetails({...newDetails, firstName})} />
      <TextInputV2 label="Middle Name (Optional)" onValueChange={middleName => setNewDetails({...newDetails, middleName})} required={false} />
      <TextInputV2 label="Last Name" onValueChange={lastName => setNewDetails({...newDetails, lastName})} />
      <Button onClick={onSubmit}>Change my name</Button>
    </Loading>
  );
}

export default StudentAccountPage;
