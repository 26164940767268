import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContextProvider';
import { SessionContext } from '../../../context/SessionContextProvider';
import useWithLoading from '../../../hooks/useWithLoading';
import { useHistory } from 'react-router-dom';
import { registerForEvent } from '../../../services/events';
import { ALERT_ERROR_MESSAGE } from '../../../utils/ResponseUtils';
import SingleSelectInputFromList from '../common/SingleSelectInputFromList';
import TextBoxInput from '../common/TextBoxInput';
import CheckBoxInput from '../common/CheckBoxInput';
import { PrettyForm } from '../common/PrettyForm';
import PaymentMethodSelection from '../payment/PaymentMethodSelection';
import "./FormProcessor.css"
import Tooltip from '../../common/Tooltip';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import BundleSelection from '../payment/BundleSelection';
import DeliverySelection from '../payment/DeliverySelection';
import { BundleContext } from '../../../context/BundleContextProvider';

/**
 * Reads json of a form template and generates an equivalent form
 * @param formJson        JSON file following form-schema.json
 * @param formModificationHandler  if non-null, the form is rendered in preview mode
 *                                 Supply a function that can react to events from the form
 *                                 The function should be of a type (event: String, idx: int) => {}.
 * @returns {JSX.Element}
 * @constructor
 */
const FormProcessor = ({
  formJson,
  formModificationHandler = null
}) => {
  const {
    id,
    name,
    elements,
    addons
  } = formJson;

  const { authRetrievedProfile } = useContext(AuthContext);
  const { session } = useContext(SessionContext);

  const { price, bundle } = useContext(BundleContext)
  const { defaultPrice, discountedPrice, total } = price

  const [loading, withLoading] = useWithLoading();
  const history = useHistory();
  const isPreviewMode = !!formModificationHandler
  const onSubmit = isPreviewMode
    ? () => {}
    : () => withLoading(
      registerForEvent(authRetrievedProfile, formJson.id, session.paymentMethod, session, bundle),
      (res) => window.location.replace(res?.data),
      ALERT_ERROR_MESSAGE,
    );

  const Modifier = ({children, idx})=>{
    return (
      <div key={idx} style={{
        position: 'relative'
      }}>
        {children}
        <div className={'form-input-modifier form-input-up'} onClick={() => formModificationHandler('up', idx)}>
          <i className="bi bi-chevron-up" />
        </div>
        <div className={'form-input-modifier form-input-down'} onClick={() => formModificationHandler('down', idx)}>
          <i className="bi bi-chevron-down"/>
        </div>
        <div className={'form-input-modifier form-input-delete'} onClick={() => formModificationHandler('delete', idx)}>
          <i className="bi bi-x-lg"/>
        </div>

      </div>
    )

  }

  const formInputs = elements.map(element => {
    const {
      formInput,
      label,
      field,
      data
    } = element;
    switch (formInput) {
      case 'required-select-one':
        return <SingleSelectInputFromList label={label} field={field} data={data} required={true}/>;
      case 'select-one':
        return <SingleSelectInputFromList label={label} field={field} data={data} required={false}/>;
      case 'required-input-text':
        return <TextBoxInput label={label} field={field} required={true}/>;
      case 'input-text':
        return <TextBoxInput label={label} field={field} required={false}/>;
      case 'required-tick':
        return <CheckBoxInput label={label} field={field} required={true}/>;
      case 'tick':
        return <CheckBoxInput label={label} field={field} required={false}/>;
      case 'header':
        return <><h3 className={"mt-3"}>{label}</h3></>;
      default:
        return <p>Invalid input type requested: {formInput}</p>;
    }
  })
    .map(((element, idx) => {
      return formModificationHandler
        ? <Modifier key={idx} idx={idx}>
          {element}
        </Modifier>
        : <div key={idx}>{element}</div>;
    }));

  const BasePrice = ()=>{
    if(defaultPrice !== discountedPrice){
      return(
        <span><i className="bi bi-tag text-danger"/> <s className={"text-danger"}>{defaultPrice}</s> <i className="bi bi-arrow-right"/> <span className={"fw-bold"}>{discountedPrice}</span> NZD <span className={"text-muted small"}>(EQI discount applied)</span></span>
      )
    }else {
      return(
        <span><i className="bi bi-tag"/> {defaultPrice} NZD</span>
      )
    }
  }

  return (
    <PrettyForm
      submitText={'Next'}
      onSubmit={onSubmit}
      cancelText="Cancel"
      onCancel={() => history.goBack()}
      loading={loading}
      forPreview={formModificationHandler}
    >
      <h6 className={'display-4'}>{name}</h6>
      <Row>
        <Col lg={7}>
          {formInputs}
        </Col>
        <Col lg={5}>
          <Card className={'p-3 mb-4'}>
            <h1 className="lead fw-bold mb-3">Base Price</h1>
            <BasePrice />
          </Card>
          <BundleSelection addons={addons}/>
          <DeliverySelection/>
          <PaymentMethodSelection eventCode={id} isPreviewMode={isPreviewMode}/>
          <br/>
          <Alert>
            <Alert.Heading>
              Disclaimer
            </Alert.Heading>
            <p className="pt-3 text-black-50 small font-italic">
              - Payments are non-refundable and non-transferable.
              <br/>
              - By continuing, you agree to receive emails from us, both informational and promotional.
              You may opt out any time by contacting us at contact@nzpmc.com.
            </p>
          </Alert>
        </Col>
      </Row>

    </PrettyForm>
  );
};

export default FormProcessor;
