import 'bootstrap-icons/font/bootstrap-icons.css';
import { useContext } from 'react';
import { Stack } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { ExamContext } from '../../pages/ExamPage';
import RichTextDisplay from '../forms/common/richtext/RichTextDisplay';
import classes from './ExamQuestion.module.css';
import QuestionTypeMultiChoice from './QuestionTypes/QuestionTypeMultiChoice';
import QuestionTypeShortAnswer from './QuestionTypes/QuestionTypeShortAnswer';

/**
 * Notice that the component has setAnswer as a prop.
 * This is to make the setting of the answer in the parent component more customizable.
 *
 * @param {*} question - The question object that will be displayed
 * @param {*} index - The index of the question in the exam (Needed for displaying the question number)
 * @param {*} randomize - A boolean that determines if the choices should be randomized. Defaults to false.
 */

export default function ExamQuestion({ question, index }) {
  const { flags, toggleFlag } = useContext(ExamContext);

  return (
    <div className={classes.wrapper}>
      <Card className={classes.questionCard} id={question.id}>
        <Card.Body>
          {index !== undefined && (
            <>
              <Stack direction="horizontal" gap={3}>
                <Card.Title>{`Question ${index + 1}`}</Card.Title>
                <Card
                  onClick={() => toggleFlag(question.id)}
                  className={classes.flag}
                >
                  {flags?.includes(question.id) ? (
                    <i
                      className="bi bi-flag-fill"
                      style={{ color: 'orange' }}
                    />
                  ) : (
                    <i className="bi bi-flag" />
                  )}
                </Card>
              </Stack>

              <hr />
            </>
          )}
          <RichTextDisplay content={question.body} />
          <hr />
          <AnswerInput question={question} />
        </Card.Body>
      </Card>
    </div>
  );
}

function AnswerInput({ question }) {
  const { type } = question;
  if (type === 'ShortAnswer') {
    return <QuestionTypeShortAnswer question={question} />;
  }
  if (type === 'MultipleChoice') {
    return <QuestionTypeMultiChoice question={question} />;
  }
  return type ? (
    <p>{`Question Type "${type}" Not Supported`}</p>
  ) : (
    <p>Question Missing Question Type</p>
  );
}
