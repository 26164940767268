import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import useWithLoading from '../../hooks/useWithLoading';
import { AuthContext } from '../../context/AuthContextProvider';
import { getJoinedExams } from '../../services/exams';
import { ALERT_ERROR_MESSAGE } from '../../utils/ResponseUtils';
import Loading from '../common/Loading';
import ExamCard from './ExamCard';
import { STUDENT_ROLE } from '../../utils/roleComputation';

function ExamList() {
  const [exams, setExams] = useState();
  const [loading, withLoading] = useWithLoading();
  const { authRetrievedProfile } = useContext(AuthContext);

  const isStudent = authRetrievedProfile?.role === STUDENT_ROLE;

  useEffect(() => {
    if (!isStudent) {
      return;
    }
    withLoading(
      getJoinedExams(authRetrievedProfile),
      (res) => setExams(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, [isStudent]);

  // Return null if exams is undefined or empty
  if (!loading && (exams === undefined || Object.keys(exams).length === 0)) {
    return null;
  }

  return (
    <Loading loading={loading} message="Retrieving exams">
      <Row className="mb-3">
        <p className="fw-bold text-muted">Exams</p>
        <Row xs={1} md={2} lg={3} xl={4}>
          {exams
            ? Object.entries(exams).map(([eventCode, exam]) => (
              <Col key={eventCode}>
                <ExamCard eventCode={eventCode} exam={exam} />
              </Col>
            ))
            : null}
        </Row>
      </Row>
    </Loading>
  );
}

export default ExamList;
