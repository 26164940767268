import { useState } from 'react';
import { Card, Stack } from 'react-bootstrap';
import classes from './CompactQuestion.module.css';
import { QuestionPreview } from './QuestionPreview';


export default function CompactQuestion({ question, changeQuestion }) {
  const [viewed, setViewed] = useState(false);

  return (
    <Card className="mt-3 col-lg-7 mx-auto rounded">
      <Card.Body>
        <Stack direction="horizontal" gap={3}>
          <Card className="border-0" onClick={() => setViewed(!viewed)}>
            <Card.Text className={classes.clickable}>
              <i className={viewed ? 'bi bi-chevron-down' : 'bi bi-chevron-right'} />
              {' '}
              <span className={classes.clickable}>{question.title}</span>
            </Card.Text>
          </Card>
        </Stack>
      </Card.Body>
      {viewed && (
        <QuestionPreview question={question} changeQuestion={changeQuestion} />
      )}
    </Card>
  );
}
