import { Form } from 'react-bootstrap';
import { useContext, useState } from 'react';
import RichTextDisplay from '../../forms/common/richtext/RichTextDisplay';
import classes from '../ExamQuestion.module.css';
import { ExamContext } from '../../../pages/ExamPage';

function QuestionTypeMultiChoice({ question }) {
  const { userChoices, handleAnswer } = useContext(ExamContext);
  const [selected, setSelected] = useState(userChoices ? userChoices[question.id] : '');

  const handleClick = (choice) => {
    setSelected(choice);
    handleAnswer(question.id, choice);
  };

  return (
    <Form>
      {Object.keys(question.choices)?.map((choice) => (
        <Form.Check
          type="radio"
          label={(
            <div onClick={() => handleClick(choice)}>
              <RichTextDisplay content={question.choices[choice]} />
            </div>
          )}
          className={classes.radio}
          name="questionOptions"
          key={`question-${question.id}-option-${choice}`}
          checked={selected === choice}
          onChange={() => handleClick(choice)}
        />
      ))}
    </Form>
  );
}

export default QuestionTypeMultiChoice;
