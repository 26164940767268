import { Button, Card, Col, InputGroup } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { BundleContext } from '../../../context/BundleContextProvider';
import Form from 'react-bootstrap/Form';
import { AuthContext } from '../../../context/AuthContextProvider';

const DeliverySelection = () => {
  const { bundle, setDelivery } = useContext(BundleContext);
  const { authRetrievedProfile: auth } = useContext(AuthContext)
  const { addons } = bundle;
  const [ showCustomAddressInput, setShowCustomAddressInput ] = useState(false)
  const [ customAddress, setCustomAddress] = useState("")

  const [displayDelivery, setDisplayDelivery] = useState(false);

  useEffect(() => {
    const requiresDelivery = Object.values(addons).some(addon => addon.isDeliveryRequired);
    setDisplayDelivery(requiresDelivery);
  }, [bundle]);

  const setAddress = () => {
    // update custom address value
    if (bundle.delivery.isCustomAddress) {
      setDelivery(true, customAddress);
    }
  }


  if (!displayDelivery) {
    return <></>;
  }

  const DELIVER_TO_SCHOOL = "school"
  const DELIVER_TO_YOUR_ADDRESS = "custom"
  const changeDeliveryMethod = e => {
    const deliveryMethod = e.target.id
    if (deliveryMethod === DELIVER_TO_SCHOOL) {
      setDelivery(false, null)
      setShowCustomAddressInput(false)
    }
    if (deliveryMethod === DELIVER_TO_YOUR_ADDRESS) {
      setDelivery(true, "")
      setShowCustomAddressInput(true)
    }
  }

  const radioGroupName = "delivery-method"

  const isAddressNonEmpty = bundle.delivery.address && bundle.delivery.address.length !== 0

  const sendToCustomAddress = !!bundle.delivery.isCustomAddress
  return (<>
    <Card className={"p-3"}>
      <Col xs={12}><h1 className="lead fw-bold mb-3">Delivery</h1></Col>
      <div>
        <Form.Check type={"radio"} name={radioGroupName} id={DELIVER_TO_SCHOOL} checked={!sendToCustomAddress} label={<span><b>[Recommended]</b> Send to school (+$2)</span>} onChange={changeDeliveryMethod} />
        <Form.Check type={"radio"} name={radioGroupName} id={DELIVER_TO_YOUR_ADDRESS} checked={sendToCustomAddress} label={"Send to your address (+$5)"} onChange={changeDeliveryMethod} />
      </div>
      {
        showCustomAddressInput &&
        <InputGroup>
          <Form.Control
            onChange={e=>setCustomAddress(e.target.value)}
            type='text'
            name="custom address"
            placeholder={"Enter full address"}
          />
          <Button onClick={setAddress}>Set Address</Button>
        </InputGroup>
      }
      <>
        <br/>
        <small>
          <i className="bi bi-send"/>
          {sendToCustomAddress
            ? <> {isAddressNonEmpty
              ? <>We will send it to <b>{bundle.delivery.address}</b></>
              : <i className={"text-muted"}>Enter your address (street, suburb, city, postcode)</i>}</>
            : <> We will send it to <b>{auth.schoolName}</b></>}
        </small>
      </>
    </Card>
      <br/>
    </>
  );
}

export default DeliverySelection;
