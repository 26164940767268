import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { ADMIN_ROLE, getRole } from '../../../../utils/roleComputation';
import { EventContext } from '../../../../pages/EventPage';
import { Card } from 'react-bootstrap';

export default function AttachJuniorExamButton({className}) {
  const history = useHistory()
  const {authRetrievedProfile: auth} = useContext(AuthContext);
  const { eventCode, juniorExamCode } = useContext(EventContext);

  if(getRole(auth) !== ADMIN_ROLE) return null

  if (!juniorExamCode) {
    return (<a className={className} onClick={()=>history.push(`/admin/createExam/${eventCode}/Junior`)}>
      {" "} Create Exam (Junior)
    </a>);
  } else {
    return <div className={"p-2 rounded-3 my-3"} style={{
      border: "1px solid darkgray",
      backgroundColor: "antiquewhite"
    }}>
      <small>Junior Exam ID: {juniorExamCode}</small>
      <a className={className} onClick={()=>history.push(`/admin/createQuestion/${juniorExamCode}`)}>{" "} Add question</a>
      <a className={className} onClick={()=>history.push(`/admin/previewExam/${juniorExamCode}`)} >{" "} View exam</a>
    </div>
  }
}
