import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContextProvider';
import { Card, Col, ListGroupItem, Modal } from 'react-bootstrap';
import StudyImage from './study.png';
import { getFormattedNZTDateString } from '../../utils/AnnouncementUtils';
import './EventCard.css'
import EventPage from '../../pages/EventPage';
import useSimpleModal from '../../hooks/useSimpleModal';

export default function EventCard({ event }) {
  const history = useHistory();
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  const { setModalVisible, ModalWrapper } = useSimpleModal()


  const handleEventCardButtonClick = () => {
    history.push(`/dashboard/${event?.eventCode}`);
  };

  const daysBeforeRegistrationClosure = Number((new Date(event?.registrationDueDate) - new Date())/1000/60/60/24).toFixed(0)
  const hoursBeforeRegistrationClosure = Number(((new Date(event?.registrationDueDate) - new Date())/1000/60/60).toFixed(0)) + 1
  const isPastRegistration = daysBeforeRegistrationClosure <= 0

  return (
    <div className="event-card p-3 mb-3" onClick={handleEventCardButtonClick}>
      <img className="event-card-img" src={StudyImage} />
      <div className={"small small"}>
        <span className={"fw-bold"}>{event?.eventName}</span><br/>
        <span className={"text-muted fw-light"}><i className="bi bi-calendar-check"></i> {getFormattedNZTDateString(event?.eventDate)}<br/></span>
        <span className={"text-muted fw-light"}>
          <i className="bi bi-file-person-fill"></i>{" "}
          {!isPastRegistration ? <>
            Closes within {
            daysBeforeRegistrationClosure < 3 ?
              <span className={"fw-bold"} style={{ color: "palevioletred" }}>{hoursBeforeRegistrationClosure} hour(s)!</span> :
              <span>{daysBeforeRegistrationClosure} day(s)</span>}
          </> : <span>Registration closed</span>}
        </span>
      </div>
    </div>
  );
}
