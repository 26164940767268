import { Card, Button } from 'react-bootstrap';
import { useState } from 'react';
import StudyImage from './study.png';
import ExamStartModal from './ExamStartModal';

function ExamCard({ eventCode, exam }) {
  const [showModal, setShowModal] = useState(false);

  const startTime = new Date(exam.examStartTime);
  const endTime = new Date(exam.examEndTime);
  // TODO - Set as actual duration (not yet in backend at time of this commit)
  const duration = 120;

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  let cardButton;
  if (startTime > new Date()) {
    cardButton = <Button variant="secondary" onClick={openModal}>Info</Button>;
  } else if (endTime < new Date()) {
    cardButton = <Button variant="outline-secondary" disabled>Ended</Button>;
  } else {
    cardButton = <Button variant="success" onClick={openModal}>Open</Button>;
  }

  return (
    <Card className="shadow p-4 mb-3">
      <Card.Img src={StudyImage} />
      <Card.Body className="p-2 mt-3">
        <Card.Title className="mb-2">{exam.eventName}</Card.Title>
        <Card.Subtitle className="mb-3">{startTime.toLocaleDateString()}</Card.Subtitle>
        <Card.Text className="mb-2">{`Starts: ${startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</Card.Text>
        <Card.Text className="mb-3">{`Duration: ${duration} minutes`}</Card.Text>
      </Card.Body>
      {cardButton}
      <ExamStartModal show={showModal} handleClose={closeModal} eventCode={eventCode} exam={exam} />
    </Card>
  );
}

export default ExamCard;
