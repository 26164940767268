import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PrettyForm } from '../forms/common/PrettyForm';
import { AuthContext } from '../../context/AuthContextProvider';
import MultiChoiceBuilderV2 from '../forms/exam/stateless/MultiChoiceBuilderV2';
import { toast } from 'react-toastify';
import RichTextInputV2 from '../forms/common/stateless/RichTextInputV2';
import TextInputV2 from '../forms/common/stateless/TextInputV2';
import SelectInputV2 from '../forms/common/stateless/SelectInputV2';
import FileInputV2 from '../forms/common/stateless/FileInputV2';
import { DIFFICULTY_TAG, DIVISION_TAG, TOPIC_TAG } from './QuestionAttributeConstants';

function CreateMultipleChoiceQuestion({ createQuestion }) {
  const CHOICE_ARRAY_SIZE = 4;
  const { authRetrievedProfile } = useContext(AuthContext);

  const [question, setQuestion] = useState({
    questionOwner: authRetrievedProfile.name,
    type: "MultipleChoice",
    rawChoiceArray: Array(CHOICE_ARRAY_SIZE).fill(''),
    rawCorrectIndex: ''
  });

  const [imageFile, setImageFile] = useState(null);

  const history = useHistory();

  const onChoiceArrayChange = (choiceIndex, newChoice) => {
    checkIndexOutOfBound(question.rawChoiceArray, choiceIndex)

    setQuestion({
      ...question,
      rawChoiceArray: question.rawChoiceArray.map((choice, index) => index === choiceIndex ? newChoice : choice)
    });
  }

  const onCorrectChoiceIndexChange = (correctChoiceIndex) => {
    checkIndexOutOfBound(question.rawChoiceArray, correctChoiceIndex)

    setQuestion({
      ...question,
      rawCorrectIndex: correctChoiceIndex
    });
  }

  const checkIndexOutOfBound = (array, index) => {
    if (array.length <= index) {
      toast.error(`Index out of bounds ${array.length}, ${index}`);
      return false;
    }
    return true;
  }

  return (
    <PrettyForm
      submitText="Create Question"
      cancelText="Cancel"
      onCancel={() => history.goBack()}
      onSubmit={() => createQuestion(question, imageFile)}
    >
      <TextInputV2 label="Title" onValueChange={title => setQuestion({ ...question, title })} required />
      <RichTextInputV2 label="Body" onValueChange={body => setQuestion({ ...question, body })} required />
      <FileInputV2 label="Image (Drag and Drop)" onValueChange={image => setImageFile(image)} />
      <SelectInputV2
        label={"Topic"}
        onValueChange={topic => setQuestion({ ...question, topic })}
        options={TOPIC_TAG}
        required
      />
      <SelectInputV2
        label={"Difficulty"}
        onValueChange={difficulty => setQuestion({ ...question, difficulty })}
        options={DIFFICULTY_TAG}
        required
      />
      <SelectInputV2
        label={"Division"}
        onValueChange={division => setQuestion({ ...question, division })}
        options={DIVISION_TAG}
        required
      />
      <MultiChoiceBuilderV2 label="Multichoice (Select Correct Answer)" onChoiceArrayChange={onChoiceArrayChange} onCorrectChoiceIndexChange={onCorrectChoiceIndexChange} choiceArraySize={CHOICE_ARRAY_SIZE} />
      <em>{`Question Owner: ${question.questionOwner}`}</em>
    </PrettyForm>
  );
}


export default CreateMultipleChoiceQuestion;
