import './LinkCard.css'

export default function LinkCard({title, children, onClick, color}){
  return(
    <div className={"link-card"} style={{background: color}} onClick={onClick}>
      <div className={'link-card-content'}>
        <span className={"fw-bold fs-5"}>{title}</span> <br/>
        <span>{children}</span>
      </div>
    </div>
  )
}
