import { EditorContent, useEditor } from '@tiptap/react';
import 'katex/dist/katex.min.css';
import React from 'react';
import { RICH_TEXT_EXTENSIONS } from './config';
import styles from './RichTextInput.module.css';

function RichTextDisplay({ content, ...rest }) {
  const editor = useEditor({ extensions: [...RICH_TEXT_EXTENSIONS] });
  if (!editor) return null;

  editor.setEditable(false);
  editor.commands.setContent(content);

  return (
    <div {...rest} className={`${styles.editor} p-3 ${rest.className}`}>
      <EditorContent editor={editor} />
    </div>
  );
}

export default RichTextDisplay;
