import 'bootstrap-icons/font/bootstrap-icons.css';
import { Container } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { Footer, Instruction, Navigation } from './SiteLevelComponents';
import YearLevelUpdater from './components/dashboard/admin/YearLevelUpdater';
import { AuthContextProvider } from './context/AuthContextProvider';
import { BundleContextProvider } from './context/BundleContextProvider';
import { PageContextProvider } from './context/PageContextProvider';
import { SessionContextProvider } from './context/SessionContextProvider';
import AccountPage from './pages/AccountPage';
import AnnouncementListPage from './pages/AnnouncementListPage';
import AnnouncementPage from './pages/AnnouncementPage';
import CreateAnnouncementPage from './pages/CreateAnnouncementPage';
import CreateEventPage from './pages/CreateEventPage';
import CreateExamPage from './pages/CreateExamPage';
import CreateFormPage from './pages/CreateFormPage';
import CreateQuestionPage from './pages/CreateQuestionPage';
import DashboardPage from './pages/DashboardPage';
import EventListPage from './pages/EventListPage';
import EventPage from './pages/EventPage';
import ExamEndScreen from './pages/ExamEndScreen';
import ExamPage from './pages/ExamPage';
import FAQPage from './pages/FAQPage';
import LoginPage from './pages/LoginPage';
import MySchoolPage from './pages/MySchoolPage';
import PaymentCancelled from './pages/PaymentCancelled';
import PaymentFailure from './pages/PaymentFailure';
import Payment from './pages/PaymentPage';
import { PreviewExamPage } from './pages/PreviewExamPage';
import RegisteredStudentsPage from './pages/RegisteredStudentsPage';
import ReleaseNotePage from './pages/ReleaseNotePage';
import StudentSignupPage from './pages/StudentSignupPage';
import TeacherSignupPage from './pages/TeacherSignupPage';
import ThankYou from './pages/ThankYou';

function App() {
  return (
    <div className="App">
      <div className="Main">
        <SessionContextProvider>
          <AuthContextProvider>
            <ToastContainer
              position="bottom-left"
              newestOnTop={false}
              autoClose={8000}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <Navigation />
            <PageContextProvider>
              <BundleContextProvider>
                <Instruction />
                <Container className="my-3">
                  <Switch>
                    <Route exact path="/faq">
                      <FAQPage />
                    </Route>
                    <Route exact path="/releaseNotes">
                      <ReleaseNotePage />
                    </Route>
                    <Route exact path="/student">
                      <StudentSignupPage />
                    </Route>
                    <Route exact path="/teacher">
                      <TeacherSignupPage />
                    </Route>
                    <Route path="/ThankYou">
                      <ThankYou />
                    </Route>
                    <Route path="/Cancelled">
                      <PaymentCancelled />
                    </Route>
                    <Route path="/Failure">
                      <PaymentFailure />
                    </Route>
                    <Route path="/Payment/:eventCode">
                      <Payment />
                    </Route>
                    <Route path="/login">
                      <LoginPage />
                    </Route>
                    <Route path="/account">
                      <AccountPage />
                    </Route>
                    <Route exact path="/dashboard/:eventCode">
                      <EventPage />
                    </Route>
                    <Route
                      exact
                      path="/dashboard/registeredStudents/:eventCode"
                    >
                      <RegisteredStudentsPage />
                    </Route>
                    <Route exact path="/school">
                      <MySchoolPage />
                    </Route>
                    <Route exact path="/announcements">
                      <AnnouncementListPage />
                    </Route>
                    <Route exact path="/events">
                      <EventListPage />
                    </Route>
                    <Route exact path="/announcements/:announcementId">
                      <AnnouncementPage />
                    </Route>
                    <Route path="/admin/createAnnouncement">
                      <CreateAnnouncementPage />
                    </Route>
                    <Route path="/admin/createEvent">
                      <CreateEventPage />
                    </Route>
                    <Route path="/admin/createExam/:eventCode/:division">
                      <CreateExamPage />
                    </Route>
                    <Route path="/admin/updateYearLevel">
                      <YearLevelUpdater />
                    </Route>
                    <Route path="/admin/createQuestion/:examCode">
                      <CreateQuestionPage />
                    </Route>
                    <Route path="/admin/previewForm/:examCode">
                      Under Construction!
                    </Route>
                    <Route path="/admin/createForm/:eventCode">
                      <CreateFormPage />
                    </Route>
                    <Route path="/admin/previewExam/:examCode">
                      <PreviewExamPage />
                    </Route>
                    <Route path="/exam/:eventCode">
                      <ExamPage />
                    </Route>
                    <Route path="/examEndScreen/:eventCode">
                      <ExamEndScreen />
                    </Route>
                    <Route exact path="/">
                      <DashboardPage />
                    </Route>
                  </Switch>
                </Container>
              </BundleContextProvider>
            </PageContextProvider>
          </AuthContextProvider>
        </SessionContextProvider>
      </div>
      <Footer />
    </div>
  );
}

export default App;
