import { useContext, useEffect, useState } from 'react';
import { EventContext } from '../../../pages/EventPage';
import { AuthContext } from '../../../context/AuthContextProvider';
import { ADMIN_ROLE, getRole, STUDENT_ROLE, TEACHER_ROLE } from '../../../utils/roleComputation';

const EventStatusForUser = ()=>{
  const { registrationDueDate, eventDate, isReleased, isAvailable, isPastEvent, isNotRegistrable } = useContext(EventContext);
  const { authRetrievedProfile: auth } = useContext(AuthContext)

  return (
    <>
      {getRole(auth) === ADMIN_ROLE && <span className={'text-danger'}>{!isReleased ? '[Not released to users yet]' : '[Released to users]'} <br/></span>}
      {isAvailable && <span className={'text-success'}>Open For Registration</span>}
      {isPastEvent && <span className={'text-secondary'}>Past Event</span>}
      {isNotRegistrable && <span className={'text-danger'}>Not Registrable</span>}
      {registrationDueDate > eventDate && <span><br/>It's a weird case where registration due date is after event date</span> }
      <br/>
    </>
  );
}

export default EventStatusForUser
