import { Color } from '@tiptap/extension-color';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import Paragraph from '@tiptap/extension-paragraph';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import { Mathematics } from '@tiptap-pro/extension-mathematics';

export const RICH_TEXT_EXTENSIONS = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
  Image,
  Link.configure({
    openOnClick: true,
    autolink: true,
  }),
  Underline,
  Subscript,
  Superscript,
  Mathematics,
  Highlight.configure({ multicolor: true }),
  TextAlign.configure({ types: [Paragraph.name, Heading.name] }),
];

export const sampleRichTextContent = `
  <h3>Here's a rich text editor!</h3><br/>We recommend that you draft on Google Docs or Gmail & copy and paste it into this editor. It won't carry over images, but you can also add it by using one of the buttons.
`;
