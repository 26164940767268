import { useContext } from 'react';
import { EventContext } from '../../../../pages/EventPage';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { ADMIN_ROLE, getRole } from '../../../../utils/roleComputation';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export default function AddFormButton({className}) {
  const {form, eventCode} = useContext(EventContext)
  const {authRetrievedProfile: auth} = useContext(AuthContext)
  const history = useHistory()
  if(getRole(auth) !== ADMIN_ROLE) return null;
  if(form) return null;
  return <a className={className} onClick={()=>history.push(`/admin/createForm/${eventCode}`)}>
    {" "}Add Form
  </a>
}
