import { Card } from 'react-bootstrap';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useInterval } from '../../utils/ExamUtils';

function TimerDisplay({ examEndTime, navigateAndSubmitCallback }) {
  const [formatTime, setFormatTime] = useState('--:--:--');

  useInterval(() => {
    if (examEndTime) {
      const currentTime = new Date();
      if (currentTime.getTime() >= examEndTime) {
        navigateAndSubmitCallback() // navigate to specified location.
        return;
      }
      setFormatTime(formatTimeRemaining(currentTime, examEndTime));
    }
  }, 1000);

  return (
    <Card style={{
      width: '200px', margin: 'auto', marginTop: '20px', padding: '10px', textAlign: 'center',
    }}
    >
      <Card.Title>{formatTime}</Card.Title>
      <Card.Text>Remaining Time</Card.Text>
    </Card>
  );
}

const formatTimeRemaining = (currentTime, endTime) => {
  const remainingTime = endTime - currentTime;
  const days = Math.floor(remainingTime / 1000 / 3600 / 24);
  const hours = Math.floor(remainingTime / 1000 / 3600);
  const minutes = Math.floor(((remainingTime / 1000) % 3600) / 60);
  const seconds = Math.floor((remainingTime / 1000) % 60);

  const withPad = (num) => num.toString().padStart(2, '0');

  if (days > 0) {
    return `${days} Day${days > 1 ? 's' : ''}`;
  }
  return `${withPad(hours)}:${withPad(minutes)}:${withPad(seconds)}`;
};

export default TimerDisplay;
