import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { AuthContext } from '../context/AuthContextProvider';
import { createExamForEvent } from '../services/exams';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import useWithLoading from '../hooks/useWithLoading';
import { PrettyForm } from '../components/forms/common/PrettyForm';
import { PageContext } from '../context/PageContextProvider';
import DatePickerV2 from '../components/forms/common/stateless/DatePickerV2';
import { ExamContext } from './ExamPage';

const pageMetadata = {
  titleLH: 'Create',
  titleRH: 'Exam',
  body: 'An event is associated with one Junior and one Senior exam.',
};

function CreateExamPage() {
  const { division, eventCode } = useParams();
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => setPageMetadata(pageMetadata), []);
  const { authRetrievedProfile } = useContext(AuthContext);
  const { reload } = useContext(ExamContext)
  const history = useHistory();
  const [loading, withLoading] = useWithLoading();

  const [examConfig, setExamConfig] = useState({
    division: division,
    eventCode: eventCode
  })

  useEffect(async () => {
    setPageMetadata({
      ...pageMetadata,
      titleLH: `Create ${division} exam for`,
      titleRH: `${eventCode}`
    })
  }, []);

  const onSubmit = () => {
    console.log(examConfig)
    withLoading(
      createExamForEvent(authRetrievedProfile, eventCode, examConfig),
      () => {
        alert('Successfully created an exam!');
        reload()
        history.goBack();
      },
      ALERT_ERROR_MESSAGE
    );
  };

  return (
    <PrettyForm
      submitText="Create Exam"
      onSubmit={onSubmit}
      cancelText="Cancel"
      onCancel={() => history.goBack()}
      loading={loading}
    >
      <Row>
        <h1 className={"fs-4"}>Configure Exam Time Window</h1>
        <p>Exam will be available to students from 'Start Time'. Students can start exam at any point before 'End Time', but they should start before 'End Time - Exam Duration' in order to get the full duration.</p>
        <Col xs={12} lg={6}>
          <DatePickerV2 label={"Start Time"} onValueChange={examStartTime=>setExamConfig({...examConfig, examStartTime})} />
        </Col>
        <Col xs={12} lg={6}>
          <DatePickerV2 label={"End Time"} onValueChange={examEndTime=>setExamConfig({...examConfig, examEndTime})} required />
        </Col>
      </Row>
      <Row>
        <h1 className={"fs-4"}>TODO: configure exam duration</h1>
        <p>This should be done!!</p>
      </Row>
    </PrettyForm>
  );
}

export default CreateExamPage;
