import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { ExamContext } from '../../pages/ExamPage';


export default function SubmitAttemptButton() {
  const { submitUserChoices } = useContext(ExamContext);

  const handleSubmit = () => {
    const confirm = window.confirm('Are you sure you want to submit?');
    if (!confirm) return;
    submitUserChoices()
  };
  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <Button onClick={handleSubmit}>
        Submit Exam
      </Button>
    </div>
  );
}
