import { useContext, useEffect, useState } from 'react';
import {
  Alert, Breadcrumb, Col, Row,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Instructions from '../components/common/Instructions';
import Loading from '../components/common/Loading';
import NZTDatePicker from '../components/forms/common/NZTDatePicker';
import { PrettyForm } from '../components/forms/common/PrettyForm';
import SingleSelectInputFromList from '../components/forms/common/SingleSelectInputFromList';
import TextBoxInput from '../components/forms/common/TextBoxInput';
import RichTextInputV2 from '../components/forms/common/stateless/RichTextInputV2';
import { AuthContext } from '../context/AuthContextProvider';
import { PageContext } from '../context/PageContextProvider';
import { SessionContext } from '../context/SessionContextProvider';
import useWithLoading from '../hooks/useWithLoading';
import { createAnnouncement } from '../services/announcement';
import { getAllAvailableEventsNames } from '../services/events';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import { ADMIN_ROLE } from '../utils/roleComputation';

const pageMetadata = {
  titleLH: 'Admin tool:',
  titleRH: 'Create Announcement',
  body: 'Create announcements! Creating an announcement also emails everyone - be careful.',
};

function createAnnouncementPage() {
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => setPageMetadata(pageMetadata), []);
  const { authRetrievedProfile } = useContext(AuthContext);
  const { session } = useContext(SessionContext);
  const history = useHistory();

  useEffect(() => {
    // We do a security check to ensure that only an admin account is on this page.
    if (!authRetrievedProfile || authRetrievedProfile.role !== ADMIN_ROLE) {
      history.push('/announcements');
    }
  }, []);

  const formulateAnnouncement = () => {
    const announcement = { ...session };
    announcement.attachmentURLs = [session.attachment1, session.attachment2].filter(Boolean);
    announcement.scheduledDate = new Date(session.scheduledDate);
    return announcement;
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push('/announcements')}>Announcements</Breadcrumb.Item>
        <Breadcrumb.Item active>Create Announcement Page</Breadcrumb.Item>
      </Breadcrumb>
      <Editor formulateAnnouncement={formulateAnnouncement} />
    </>
  );
}

function Editor({ formulateAnnouncement }) {
  const [formLoading, withformLoading] = useWithLoading();
  const [eventListLoading, withEventListLoading] = useWithLoading();
  const { authRetrievedProfile } = useContext(AuthContext);
  const { session, handleSessionChange } = useContext(SessionContext);
  const [eventList, setEventList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    withEventListLoading(
      getAllAvailableEventsNames,
      (res) => setEventList(res.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);

  const addAnnouncement = async () => {
    // Create payload and attempt to post.
    const { email, code } = authRetrievedProfile;
    const announcement = formulateAnnouncement();
    withformLoading(
      async () => createAnnouncement({ email, code, announcement }),
      () => {
        alert('Announcement created!');
        history.push('/announcements');
      },
      ALERT_ERROR_MESSAGE,
    );
  };

  return (
    <PrettyForm
      submitText="Create Announcement"
      onSubmit={addAnnouncement}
      cancelText="Back to announcements"
      onCancel={() => history.push('/announcements')}
      loading={formLoading}
    >

      <Row>
        <Col xs={12} lg={8}>
          <TextBoxInput label="Title" field="title" required />
          <RichTextInputV2
            label="Content"
            required
            content={session.body}
            // { target: { value: html } } simulates a native form event to satisfy the API...
            onValueChange={(html) => handleSessionChange('body')({ target: { value: html } })}
          />
          <Row>
            <Col><TextBoxInput label="PDF 1" field="attachment1" required={false} /></Col>
            <Col><TextBoxInput label="PDF 2" field="attachment2" required={false} /></Col>
          </Row>
        </Col>
        <Col xs={12} lg={4}>
          <div>
            <Loading key="events" loading={eventListLoading} message="Fetching Event List...">
              <SingleSelectInputFromList label="Event" field="forEventCode" data={eventList} />
            </Loading>
            <NZTDatePicker key="date" label="Schedule" field="scheduledDate" options={{ hourOnly: true }} />
            <Alert variant="info">
              <Alert.Heading>Help</Alert.Heading>
              <br />
              <CreateAnnouncementInstructions />
            </Alert>
          </div>
        </Col>
      </Row>
    </PrettyForm>
  );
}

function CreateAnnouncementInstructions() {
  return (
    <>
      <Instructions header="Where is Preview?">
        We recommend scheduling the announcement and checking it in the announcements, as that would be the most accurate version of what would be released.
      </Instructions>
      <Instructions header="Who can see my announcement?">
        Everyone!
      </Instructions>
      <Instructions header="Create a non-Event Announcement">
        If you want a broadcast to everyone without bounding the announcement to specific event, don't select the event (that is, put it on 'Select...' option), and create the announcement
      </Instructions>
      <Instructions header="Immediately release Announcement">
        Leave the 'schedule' as default (nothing selected).
      </Instructions>
      <Instructions header="Attach PDF">
        <li>Upload your attachment to Google Drive.</li>
        <li>In sharing settings, ensure that the file is public.</li>
        <li>Open the file, click the triple dots (top right) and open in a new window.</li>
        <li>
          Click the triple dots again and select "embed items".
          <i>
            You should see: &lt;iframe src="LINK_HERE" width="X" height="Y"
            allow="autoplay"&gt;&lt;/iframe&gt;.
            {' '}
          </i>
        </li>
        <li>Copy the link within the quotes "LINK_HERE" and paste the link in the attachments field.</li>
      </Instructions>
    </>
  );
}



export default createAnnouncementPage;
