import {
  Alert,
  Badge,
  Breadcrumb,
  Button, Card, Col, Image, Row, Stack,
} from 'react-bootstrap';
import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AnnouncementList from '../components/announcement/AnnouncementList';
import useWithLoading from '../hooks/useWithLoading';
import { declineEvent, getEvent } from '../services/events';
import { AuthContext } from '../context/AuthContextProvider';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import Loading from '../components/common/Loading';
import { getFormattedNZTDateString } from '../utils/AnnouncementUtils';
import EventStatusForUser from '../components/events/contextbound/EventStatusForUser';
import EventActionGroup from '../components/events/contextbound/EventActionGroup';
import EventAnnouncementsDisplay from '../components/events/contextbound/EventAnnouncementsDisplay';

const EventContext = createContext({
  eventCode: "",
  eventName: "",
  isReleased: false,
  shortDescription: "",
  registrationDueDate: "",
  eventDate: "",
  form: {},
  userTicket: {},
  // to put into backend
  location: "",
  // derived fields
  isPastEvent: false,
  isNotRegistrable: false,
  isAvailable: false,
  juniorExamCode: "",
  seniorExamCode: "",

  // reload event context, with the eventCode that was already registered
  reload: ()=>{}
})

function EventPage() {
  const { eventCode } = useParams();

  const [event, setEvent] = useState();
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  const [loading, withLoading] = useWithLoading();
  const history = useHistory();

  useEffect(() => {
    reload()
  }, []);

  const reload = () => {
    withLoading(
      getEvent(auth, eventCode),
      (res) => setEvent(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }

  if(!event) return <></>

  const context = {
    ...transformEventToContext(event),
    reload
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>{eventCode}</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Loading loading={loading}>
          <EventContext.Provider value={context}>
            <EventStatusForUser/>
            <Col xs={12} md={8}>
              <div className={"d-flex justify-content-between"}>
                <EventTitle/>
              </div>
              <EventBody />
            </Col>
            <Col xs={12} md={4}>
              <EventActionGroup />
              <EventAnnouncementsDisplay />
              <br/>
            </Col>
          </EventContext.Provider>
        </Loading>
      </Row>
    </>
  );
}


const transformEventToContext = (event)=>{
  const eventDate = new Date(event?.eventDate)
  const registrationDueDate = new Date(event?.registrationDueDate)

  const isPastEvent = new Date() > eventDate
  const isNotRegistrable = !isPastEvent && (new Date() > registrationDueDate)
  const isAvailable = !isPastEvent && !isNotRegistrable

  return {
    ...event,
    location: event?.location || "Somewhere over the rainbow",
    registrationDueDate,
    eventDate,
    isPastEvent,
    isNotRegistrable,
    isAvailable,
  }
}


const EventTitle = ()=>{
  const { eventName } = useContext(EventContext);
  return (
    <span className={'fs-3'}>
      {eventName}
    </span>
  )
}

const EventBody = ()=>{
  const {shortDescription, eventDate, registrationDueDate} = useContext(EventContext)
  const eventDateString = getFormattedNZTDateString(eventDate)
  const registrationDateString = getFormattedNZTDateString(registrationDueDate)

  const location = "Somewhere over the rainbow"

  return (
    <div>
      <div className={"p-2 rounded-3 my-3"} style={{border: "1px solid darkgray", backgroundColor: "ghostwhite"}}>
        {location && <small><i className="bi bi-geo-alt me-1"/>{location}<br/></small>}
        <small><i className="bi bi-calendar-event me-1"/>Event Time: {eventDateString}</small><br/>
        <small><i className="bi bi-calendar-event me-1"/>Registration Deadline: {registrationDateString}</small><br/>
      </div>
      <span className={"fs-6"}>{shortDescription}</span><br/>
    </div>
  )
}

export default EventPage;
export { EventContext };
