import { Button, Card, Stack } from 'react-bootstrap';
import FocusInput from '../../forms/common/stateless/FocusInput';
import styles from './PreviewMultiChoice.module.css';

export default function PreviewMultiChoice({ question, changeQuestion }) {
  function changeChoiceText(choiceKey, newText) {
    const choices = { ...question.choices, [choiceKey]: newText };
    changeQuestion({ ...question, choices });

    // Insert server & error handling logic
    // On fail -> setQuestions(oldQuestions);
  }

  function changeCorrectAnswer(newCorrectAnswerIndex) {
    changeQuestion({ ...question, rawCorrectIndex: newCorrectAnswerIndex });

    // Insert server & error handling logic
    // On fail -> setQuestions(oldQuestions);
  }

  return (
    <Card className="overflow-hidden">
      {Object.entries(question.choices).map(
        (choice, index) => (
          <SingleMultiChoice
            choice={choice}
            index={index}
            correctIndex={question.rawCorrectIndex}
            changeChoiceText={changeChoiceText}
            changeCorrectAnswer={changeCorrectAnswer}
            className={index !== 0 && 'border-top'}
          />
        ),
      )}
    </Card>
  );
}

function SingleMultiChoice({
  choice, index, correctIndex, changeChoiceText, changeCorrectAnswer, className,
}) {
  const [choiceKey, choiceText] = choice;
  const isCorrectAnswer = index === correctIndex;
  return (
    <Card key={choiceKey} className={`rounded-0 border-0 ${className}`}>
      <Stack
        direction="horizontal"
        className="align-items-stretch overflow-hidden"
      >
        {/* Y/X Correct Answer Button */}
        <Button
          variant={isCorrectAnswer ? 'success' : 'danger'}
          className={`${styles.button} rounded-0 p-0 flex-shrink-0`}
          onMouseDown={(e) => { e.preventDefault(); changeCorrectAnswer(index); }}
        />
        {/* MCQ Rich Text */}
        <FocusInput
          outerClassName="flex-grow-1 rounded-0 overflow-hidden"
          className="border-0 rounded-0"
          content={choiceText}
          onValueChange={(text) => changeChoiceText(choiceKey, text)}
        />
      </Stack>
    </Card>
  );
}
